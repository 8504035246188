.privatisation {
    .flex {
        display: flex;
        justify-content: space-between;
        margin-top: 44px;

        &-p {
            width: 532px;
        }
    }

    .mail {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        width: 352px;

        &-p {
            font-weight: 700;
            margin-bottom: 16px;
        }
    }
}

@media screen and (max-width: $tablet-width) {  
    .privatisation {
        .flex {
            flex-direction: column;
            &-p {
                width: 100%;
                padding: 16px 32px;
                text-align: center;
            }
        }
        .mail {
            width: 100%;
            &-p {
                margin: 16px 32px 32px;
            }
        }
    }
}

@media (min-width: $mobile-width) and (max-width: $tablet-width) {
    .privatisation {
        width: 100%;
        .flex {
            align-items: center;
            &-p {
                width: 80%;
            }
        }
    }
}