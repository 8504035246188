// CSS Nav au Scroll de la page
.scroll {
    .navbar {
        background: $mono-100;
        padding: 0;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        animation: slideNavbar 0.5s forwards;
        &-ul {
            border-color: $mono-200;
            &-a {
                color: $mono-900;
            }
            svg {
                fill: $mono-900;
            }
        }
        &-a {
            color: $mono-900;
            text-shadow: initial;
        }

        &-bloc {
            display: flex;
        }

        &-img {
            width: 44px;
            height: 44px;
            margin: 15px 0px;
        }
        &-link {
            align-items: center;
        }
        &-menu {
            display: flex;
        }
    }
    .menubtn {
        position: relative!important;
        margin: 0!important;
        svg *{
            fill: $mono-900;
        }
    }
}

@keyframes slideNavbar {
    0% {
        transform: translateY(-75px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media screen and (max-width: $tablet-width) { 
    .scroll .navbar-menu {
        height: 64px;
    }
}