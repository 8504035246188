@import "variables";
@import "fonts";
@import "reset";

*{
	box-sizing: border-box;
	text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; font-smoothing: antialiased;
	scroll-behavior: smooth;
}

body{
	display: flex; flex-direction: column; align-items: center;
	background: $mono-100; font-family: $secondary-font; color: $mono-900;
}

.h1 { 
	font-family: $primary-font; 
	font-size: 40px;
	line-height: 48px;
	text-shadow: 0px 4px 4px #000000;
	color: $mono-100;
}

.text {
	font-size: 18px;
	line-height: 165%;
}

.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
}

@media screen and (max-width: $mobile-width) { 
	.main {
		width: 100%;
			
	}
}

.border {
	border-bottom: 1px solid #D6E1EB 
}

strong {
	font-weight: bold;
}

.container {
	height: 100%;
	width: 100%;
}

@import "parts/header";
@import "parts/hero";
@import "components/overlay";
@import "components/button";
@import "components/section";
@import "components/bloc";
// @import "components/btnNav";
@import "components/scrollNav";
@import "parts/kitchen";
@import "parts/access";
@import "parts/history";
@import "parts/privatisation";
@import "parts/footer";