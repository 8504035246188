.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
        content: "";
        background: url(../../assets/images/separator-footer.svg);
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 4px;
        top: -5%;
    }

    .contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 48px;

        &-p {
            font-size: 26px;
            line-height: 32px;
            font-family: $primary-font;
        }

        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    #instagram{
        display: flex; align-items: center; grid-gap: 8px;
        line-height: 24px;
        svg{ display: block; width: 16px; height: 16px; }
        svg *{ fill: $mono-500; }
    }

    .margin {
        margin: 0px 32px;

        &-flex {
            display: flex;
        }

        .space {
            margin: 0px 4px;
            color: $mono-500;
        }
    }
    .logos {
        display: flex; justify-content: center; flex-wrap: wrap;
        width: 100%; padding: 0 $mobile-gutter 64px;
        img{ 
            max-width: 30vw; 
            max-height: 12vh; 
            margin-right: 64px; 
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: $tablet-width) {
    .footer {
        .contact {
            flex-direction: column;
            padding: 32px 0px 24px 0px;
        }
        .margin {
            margin: 12px 12px;
        }
        .logos {
            img {
                margin: 0 16px;
            }
        }
    }
}

@media screen and (max-width: $mobile-width) {
    .footer {
        .logos {
            justify-content: space-around;
            img {
                margin-bottom: 16px;
            }
        }
    }
}
