.history {
    &-flex {
        display: flex;
        justify-content: space-between;
        margin-top: 44px;
    }

    &-width {
        width: 480px;
    }

    &-m {
        margin-bottom: 32px;
    }

    &-img {
        width: 100%;
    }
}

@media screen and (max-width: $tablet-width) { 
    .history {
        &-flex {
            flex-direction: column;
            align-items: center;
        }
        &-width {
            width: 100%;
            text-align: center;
        }
        &-m {
            margin: 16px 32px;
        }
        &-img {
            margin: 16px 0px;
        }
    }
}

@media (min-width: $mobile-width) and (max-width: $tablet-width) {
    .history {
        &-width {
            width: 80%;
        }
        &-img {
            width: 500px;
        }
    }
}
