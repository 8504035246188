.access {
	display: flex;
	justify-content: space-between;
	margin-top: 44px;

	.map {
		width: 480px;

		&-img {
			height: 688px;
			width: 480px;
			border: 1px solid $mono-200;
		}

		&-logo {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			&-m {
				margin: 16px;
			}
		}

		&-link {
			display: none;
		}
	}

	.way {
		width: 480px;

		&-button {
			display: flex;
			justify-content: center;
		}
	}

	.return {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.point {
		margin-bottom: 64px;
	}
}

@media (max-width: $tablet-width) { 
	.access {
		flex-direction: column;
		.map {
			width: 100%;
			&-img {
				height: 352px;
				width: 100%;
			}
			&-logo-m {
				width: 80px;
				height: 80px;
			}
			&-link {
				display: flex;
				justify-content: center;
				margin: 32px 32px 16px 32px;
			}
		}
		.way {
			width: 100%;
		}
	}
}

@media (min-width: $mobile-width) and (max-width: $tablet-width) {
	.access {
		width: 100%;
		max-width: none;
		align-items: center;
		.map {
			width: 80%;
			max-width: none;
			display: flex;
			flex-direction: column;
			align-items: center;
			&-img {
				max-width: none;
				width: 100%;
			}
		}
		.way {
			width: 60%;
			.return {
				width: 100%;
			}
		}
	}
}