.bloc {
    display: flex;
    width: 100%;
    &-p {
        margin: 8px 16px;
    }

    &-img {
        margin-left: 16px;
    }
}

.none {
    display: none;
}