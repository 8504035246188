// Fonts

$primary-font: "Big Caslon", "Georgia", "Times", "Times New Roman", serif;
$secondary-font: "PT Sans", sans-serif;

// Colors

$mono-900: #222D37;
$mono-500: #798C9F;
$mono-200: #D6E1EB;
$mono-100: #FAFBFC;

// Breakpoints

$tablet-width: 1200px;
$mobile-width: 640px;

// Sizes

$mobile-size: 375px;
$section-width: 992px;
$mobile-gutter: 32px;

// Animation functions

$easeout: cubic-bezier(0.87, 0, 0.13, 1);