#header {
    position: fixed;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;

    .mobile-menu-bar {
        .logo {
            text-decoration: none;
            color: #000000;
        }
    }

    .navbar-links {
        padding: 32px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        .sections-navigation{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .navbar-link {
            display: flex; align-items: center; grid-gap: 8px;
            font-size: 18px; line-height: 32px; color: $mono-100;
            margin: 0px 32px;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            text-decoration: none;
            svg{ display: none; }
            &:hover {
                text-decoration: underline;
            }
        }
        #menu-link, #contact-link{ display: none; }

        .language {
            position: relative;
            height: 32px;
            margin: 0px 32px;
            border-radius: 32px;
            border: 1px solid $mono-100;
            svg {
                fill: $mono-100;
            }
            &-link {
                color: $mono-100;
                text-decoration: none;
                padding: 0px 8px;
            }
            li{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                font-size: 14px;
                padding: 0 16px;
            }
            li:not(:first-child){
                display: none;
                background: $mono-100;
                border-radius: 8px;
                .language-link{ color: $mono-900; }
            }
            &:hover {
                border-radius: 16px;
                cursor: pointer;
                li:not(:first-child){
                    display: flex;
                }
            }
        }
    }

    .mobile-menu-bar{
        display: none;
    }
}


@media (min-width: $tablet-width) {
    // Etat scrollé
    #header.scroll{
        background: $mono-100; box-shadow: 0 0 8px 0 rgba(black, 0.15);
        .mobile-menu-bar{
            display: flex;
            .logo{
                display: flex; align-items: center; margin-right: 160px;
                .logo-icon{ width: 64px; height: 64px; margin-right: 12px; }
                .logo-text{ font-family: $primary-font; font-size: 24px; line-height: 32px; white-space: nowrap; }
            }
            #mobile-menu-toggle{ display: none; }
        }
        .navbar-links {
            .navbar-link {
                color: $mono-900;
                text-shadow: none;
            }
        }
        .language {
            border-color: $mono-200;
            .language-link{ color: $mono-900; }
            svg{ fill: $mono-900; }
            li:not(:first-child){ border: 1px solid $mono-200; }
        }
    }
}

@media (max-width: $tablet-width) {
    #header{
        position: fixed; z-index: 10; top: 0; left: 0;
        display: flex; flex-direction: column; justify-content: flex-start;
        width: 100%; height: 100%;
        transition: all 350ms $easeout; pointer-events: none;
        .mobile-menu-bar{
            position: relative; z-index: 1; flex: 0 0 auto; height: 64px; pointer-events: all;
            display: flex; align-items: center; justify-content: center; padding: 10px 16px;
            .logo{
                display: none; align-items: center;
                .logo-icon{ width: 44px; height: 44px; margin-right: 12px; }
                .logo-text{ font-family: $primary-font; font-size: 24px; line-height: 32px; }
            }
            #mobile-menu-toggle{
                display: flex; align-items: center; justify-content: center;
                width: 32px; height: 32px; padding: 0; margin-left: auto;
                border: none; border-radius: 4px; background: no-repeat;
                svg *{ fill: $mono-100; }
                &:active{
                    background: $mono-100;
                    svg *{ fill: $mono-900; }
                }
            }
        }
        .navbar-links{
            display: flex; flex-direction: column; flex: 1; width: 82vw; padding: 32px 48px 48px 48px; margin-left: auto;
            background: $mono-100; box-shadow: 0 0 8px 0 rgba(black, 0.15);
            // Style masqué
            transition: all 350ms $easeout; transform: translateX(100%); opacity: 0;
            .sections-navigation{
                display: flex; flex: 1; flex-direction: column; align-items: center; justify-content: space-between;
                .navbar-link{
                    display: flex; flex-direction: column; align-items: center; grid-gap: 16px;
                    padding: 24px 48px;
                    font-family: $primary-font; font-size: 32px; color: $mono-900;
                    text-decoration: none; text-shadow: none;
                    svg{ display: block; width: 32px; height: 32px; }
                    svg *{ fill: $mono-900; }
                    &:hover{
                        background: $mono-900; border-radius: 8px; color: $mono-100;
                        svg *{ fill: $mono-100; }
                    }
                }
                #menu-link, #contact-link{ display: flex; }
                #kitchen-link, #privatisation-link, #history-link{ display: none; }
            }
            .language{
                display: flex; justify-content: center;
                height: auto; margin: auto 0 0 0; padding-top: 48px;
                li{
                    padding: 0;
                    &:not(:first-child){ display: flex; }
                    .language-link, &:not(:first-child) .language-link{
                        display: flex; align-items: center; height: 32px; padding: 0 12px;
                        font-size: 16px; color: $mono-500; text-decoration: none;
                    }
                    .language-img, svg{ display: none; }
                    &.current{
                        .language-link{ border: 1px solid $mono-200; border-radius: 4px; }
                    }
                    &+li{ margin-left: 24px; }
                }
            }
        }

        // Etat scrollé
        &.scroll, &.toggled{
            .mobile-menu-bar{
                background: $mono-100; box-shadow: 0 0 8px 0 rgba(black, 0.15);
                .logo{ display: flex; }
                #mobile-menu-toggle{
                    svg *{ fill: $mono-900; }
                    &:active{
                        background: $mono-500;
                        svg *{ fill: $mono-100; }
                    }
                }
            }
        }

        // Menu déployé
        &.toggled{
            pointer-events: all;
            background: rgba(black, 0.5);
            .navbar-links{
                transform: translateX(0); opacity: 1; pointer-events: all;
            }
        }
    }
}
