.button {
	text-decoration: none;
	color: $mono-900;
	padding: 12px 24px;
	border-radius: 8px;
	border: 2px #222D37 solid;
	font-family: $primary-font;
	font-size: 24px;
	line-height: 32px;
	&:hover {
		background-color: $mono-900;
		color: $mono-100;
	}
}

.buttonAccessOn {
	padding: 4px 24px;
	background: $mono-900;
	border-radius: 32px;
	color: $mono-100;
	width: 171.5px;
	height: 40px;
	font-size: 16px;
	font-weight: bold;
	line-height: 32px;
	border: none;
	margin: 16px 12px;
}

.buttonAccessOff {
	padding: 4px 24px;
	background: $mono-100;
	border-radius: 32px;
	color: $mono-500;
	width: 171.5px;
	height: 40px;
	font-size: 16px;
	font-weight: bold;
	line-height: 32px;
	border: none;
	margin: 16px 12px;
	cursor: pointer;
}