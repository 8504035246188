.section {
	width: $section-width;
	margin-bottom: 128px;

	&-title {
		position: relative;
		display: flex; flex-direction: column; align-items: flex-start; height: 48px;
		font-family: $primary-font; font-size: 40px; line-height: 48px;

		&:after {
			display: block; content: ""; flex: 0 0 auto;
			width: 96px; height: 4px; margin-top: 8px;
			background: url(../../assets/images/separator.svg) no-repeat center center / contain;
		}
	}

	.return-title {
		position: relative;
		font-family: $primary-font;
		font-size: 32px;
		line-height: 32px;
		height: 32px;
		margin-bottom: 44px;

		&::after {
			position: absolute;
			content: "";
			background: url(../../assets/images/separator.svg) no-repeat center center / cover;
			width: 96px;
			height: 4px;
			left: calc(50% - 48px);
			bottom: -12px;
		}
	}
}

@media screen and (max-width: $tablet-width) { 
	.section {
		width: 100%;
		margin-bottom: 64px;
		&-title {
			text-align: center;
			align-items: center;
		}
	}
}

@media (min-width: $mobile-width) and (max-width: $tablet-width) {
	.section {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		&-title {
			&::after {
				display: flex;
			}
		}
	}
}