.hero {
    width: 100%;

    .background {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        
        &:before {
            content: "";
            background: #222D37;
            opacity: 0.4;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    &.winter .background{
        background-image: url("../../assets/images/hero-background-winter.jpg");
    }

    &.summer .background{
        background-image: url("../../assets/images/hero-background-summer.jpg");
    }

    .content {
        text-align: center;
        z-index: 1;

        &-p {
            font-size: 28px;
            line-height: 125%;
            text-shadow: 0px 4px 4px #000000;
            color: $mono-100;
            margin: 64px 0px;
        }

        &-img {
            width: 144px;
            height: 144px;
            margin: 197px 0px 16px 0px;
            filter: drop-shadow(0px 4px 16px #000000);
        }
    }

    .reservation {
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-bloc {
            display: flex;
        }

        &-p {
            font-family: $primary-font;
            font-size: 32px;
            line-height: 32px;
            margin: 5px 8px 0px 0px;
        }

        &-phone {
            font-family: 'Times New Roman', Times, serif;
            font-size: 28px;
            line-height: 165%;
            text-decoration: none;
            color: $mono-900;
            padding: 0 8px;
            border-radius: 6px;
            &:hover {
                background-color: $mono-900;
		        color: $mono-100;
            }
            &-img {
                display: none;
            }
        }

        &-text {
            font-size: 16px;
            line-height: 165%;
        }
    }
}

@media screen and (max-width: $tablet-width) {
    .hero {
        
        .background{
            background-position: 55%;
        }
        

        .reservation {
            &-bloc {
                flex-direction: column;
                text-align: center;
            }

            &-phone {
                display: flex;
                text-decoration: none;
                font-size: 6vw;
                color: $mono-900;
                padding: 8px 24px 8px 16px;
                border-radius: 8px;
                border: 2px #222D37 solid;
                margin: 16px 0px;
                align-items: center;
                &-img {
                    display: block;
                    margin-right: 10px;
                }
            }
        }
        .content {
            &-img {
                margin: 185px 0px 16px 0px;
            }
            &-p {
                font-size: 22px;
            }   
        }
        
    }

}
