.kitchen {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &-m {
        margin: 44px 0px;
    }

    .content {
        width: 489px;
    }

    .photo {
        //width: 439px;
        display: grid;
        grid-template-columns: 1fr;     
        gap: 16px;
              
        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        &-m2 {
            //margin: 16px 16px 0px 0px;
            width: 209px; 
            height: 144px;
            object-fit: cover;
        }

        &-m3 {
            //margin-top: 16px;
            width: 209px; 
            height: 144px;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: $tablet-width) { 
    .kitchen {
        flex-direction: column;
        &-m {
            margin: 44px 32px;
        }
        .content {
            width: 100%;
            text-align: center;
        }
        .photo {
            display: flex;
            margin-top: 64px;
            width: 100%;
            overflow-x: scroll;
            &-m1 {
                width: 298px;
                height: 224px;
                object-fit: cover;
                margin: 0px 16px 0px 0px;
            }
            &-m2 {
                width: 298px;
                height: 224px;
                object-fit: cover;
                margin: 0px 16px 0px 0px;
            }
            &-m3 {
                width: 298px;
                height: 224px;
                object-fit: cover;
                margin: 0px;
            }
        }
    }
}

@media (min-width: $mobile-width) and (max-width: $tablet-width) {
    .kitchen {
        width: 100%;
        align-items: center;
        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .photo {
            width: 80%;
        }
    }
 }